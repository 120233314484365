<template>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card" id="xyz">
				<h4 class="p-m-2">Historial de Pagos y Exoneraciones</h4>
				<Toast/>
				<Toolbar class="p-mb-4">
					<template v-slot:left>
						<div class="p-fluid p-formgrid p-grid">
						<div class="p-field p-col-3">
							<label for="Periodo">Periodo</label>
							<SeleccionFecha @nuevoPeriodo="actualizarPeriodo"/>
						</div>
						<div class="p-field p-col-2 p-md-4">
							<label for="desde">Desde</label>
							<Calendar id="desde" v-model="desde" :key="desdeUpdate"/>
						</div>		
						<div class="p-field p-col-3 p-md-4">
							<label for="hasta">Hasta</label>
							<Calendar id="hasta" v-model="hasta" />
						</div>						
						<div v-if="appMode==0" class="p-field p-col-6">
							<label for="cuentahabiente">Cuentahabiente</label>
							<div class="p-fluid p-inputgroup">
							<InputText size="135" id="cuentahabiente" type="text" v-model="CtahabienteNombre" disabled/>																												
							<Button v-if="mostrarBotonBusquedaCtaHabiente" icon="pi pi-search" @click="buscarCtahabiente"/>							
							<Button v-if="!mostrarBotonBusquedaCtaHabiente" icon="pi pi-times" class="p-button-warning" @click="buscarCtahabiente"/>							
							</div>
						</div>		
						<div v-if="appMode==1" class="p-field  p-col-6">
							<label for="inmueble">Inmueble</label>
							<div class="p-inputgroup">
								<InputText size="135" id="inmueble" type="text" v-model="InmuebleDisplay" disabled/>																							
								<Button v-if="mostrarBotonBusquedaCtaHabiente" icon="pi pi-search" @click="seleccionarInmueble"/>							
								<Button v-if="!mostrarBotonBusquedaCtaHabiente" icon="pi pi-times" class="p-button-warning" @click="seleccionarInmueble"/>																			
							</div>					
						</div>					
						<div class="p-field p-col-3">
							<label for="Moneda">Moneda</label>
							<Dropdown v-model="IdMoneda" :options="lkpMonedas" optionLabel="moneda" optionValue="id_moneda" placeholder="Seleccionar"/>							
						</div>													
						<div class="p-field-checkbox p-col-3 p-mt-4">
							<label for="anulados">Mostrar Anulados</label>
							<Checkbox id="anulados" class="p-ml-2" v-model="mostrar_anulados" :binary="true" />							
						</div>										
						<div class="p-field p-col-2 p-md-2">
							<Button label="Buscar" icon="pi pi-search" class="p-button-primary p-m-2" @click="buscar" />							
						</div>																							
					</div>	
					</template>
				</Toolbar>
				<DataTable ref="dt" :value="trxs" class="p-datatable-customers" v-model:selection="selectedMovs" :rowClass="rowClass" dataKey="id_movimiento" :paginator="true" :rows="10"
							:globalFilterFields="['tipo', 'documento', 'total', 'observaciones', 'cta_habiente']" 
							v-model:filters="filters" @filter="filter"
							filterDisplay="row"							
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
							currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords}" :loading="loading">
					<template #header>
						<div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between">							
							<!-- <span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Buscar..." />
                            </span> -->
							<span class="p-input-icon-right">
								<Button label="Enviar" icon="pi pi-envelope" class="p-button-secondary" @click="dlgEnvioEmail=true" :disabled="!puedeEnviarEmails"/>
								<!-- <Button v-if="puedeDescargar" label="Descargar" icon="pi pi-download" class="p-button-primary p-ml-2" @click="exportarTrxsAXLS" :disabled="!hayDatosParaDescargar"/> -->
								<SplitButton v-if="puedeDescargar" label="Descargar" icon="pi pi-download" class="p-ml-2" :model="dowloandItems" />							    
							</span>							
						</div>
					</template>
					<Column selectionMode="multiple" headerStyle="width: 3rem"/>	
					<Column field="movimiento_tipo" header="Tipo" :sortable="true" headerStyle="width: 11rem">
						<template #body="slotProps">
							<span class="p-column-title">Tipo</span>
							{{slotProps.data.movimiento_tipo}}
						</template>
						<template #filter="{ filterModel, filterCallback }">
							<InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" />
						</template>						
					</Column>
					<Column field="documento" header="No.Docto" :sortable="true" headerStyle="width: 12rem">
						<template #body="slotProps">
							{{slotProps.data.serie}}-{{formatDocument(slotProps.data.no_docto)}}
						</template>						
						<template #filter="{ filterModel, filterCallback }">
							<InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" />
						</template>								
					</Column>
					<Column field="fecha" header="Fecha" :sortable="true" headerStyle="width: 12rem">
						<template #body="slotProps">
							<span class="p-column-title">Fecha</span>
							{{slotProps.data.fecha}}
						</template>
						<template #filter="{ filterModel, filterCallback }">
							<InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" />
						</template>								
					</Column>
					<Column field="cta_habiente" :header="tituloCtaHabiente" :sortable="true" headerStyle="width: 35rem">
						<template #body="slotProps">
							{{slotProps.data.cta_habiente}}
						</template>
						<template #filter="{ filterModel, filterCallback }">
							<InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" />
						</template>								
					</Column>	
					<Column field="total" header="Monto" :sortable="true" headerStyle="width: 15rem">
						<template #body="slotProps">
							<div class="p-d-flex p-jc-end">
								<div>{{formatCurrency(slotProps.data.id_moneda, slotProps.data.total)}}</div>
							</div>   							
						</template>						
						<template #filter="{ filterModel, filterCallback }">
							<InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" />
						</template>								
					</Column>
					<Column>
						<template #body="slotProps">							
							<Button v-if="puedeImprimir && slotProps.data.fmt" text="Imprimir" icon="pi pi-file-pdf" class="p-button-rounded p-button-primary p-mr-2" @click="descargarPDF(slotProps.data)" />
							<Button v-if="puedeAnular && slotProps.data.anulado == 'N' && !slotProps.data.id_mov_asociado" text="Anular" icon="pi pi-times" class="p-button-rounded p-button-danger p-mr-2" @click="confirmarAnulacionMov(slotProps.data)" />
							<Button v-if="puedeAnular && slotProps.data.anulado == 'N' && slotProps.data.id_mov_asociado" text="Anular" icon="pi pi-info-circle" class="p-button-rounded p-button-warning p-mr-2" @click="confirmarAnulacionMov(slotProps.data)" />
							<!-- <Button text="Enviar" icon="pi pi-envelope" class="p-button-rounded p-button-secondary p-mr-2" @click="confirmarEnvioXEmail(slotProps.data)" />														 -->
							<label v-if="slotProps.data.anulado == 'S'" class="anulado">ANULADO</label>													
						</template>
					</Column>
					<ColumnGroup type="footer">
						<Row>
							<Column v-if="IdMonedaSel != '0'" footer="Total:" :colspan="5" footerStyle="text-align:right" />
							<Column v-if="IdMonedaSel != '0'" :footer="formatCurrency(IdMonedaSel, totalDocumentos)" footerStyle="text-align:left"/>			
						</Row>
					</ColumnGroup>							
				</DataTable>

				<BuscarCtaHabiente :key="dlgBuscarCtaHabienteRefresh" :mostrar="dlgBuscarCtaHabiente" @cancelar="cerrarDlgCtahabiente" @seleccionar="selCtaHabiente"/>
				<MdsBuscarInmueble :mostrar="inmuebleDialog" @cancelar="cerrarDlgInmueble" @seleccionar="elegirInmueble"/>
				
				<Dialog v-model:visible="anularDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="mov">Está seguro que desea anular el documento <br><b>[{{mov.serie}}-{{mov.no_docto}}]</b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="anularDialog = false"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="anularTrx" />
					</template>
				</Dialog>

				<Dialog v-model:visible="movAsociadoDlg" :style="{width: '450px'}" header="Aviso" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="mov">No es posible anular este documento por que tiene un transacción vinculada!<br><b>[{{mov.serie}}-{{mov.no_docto}}]</b>?</span>
					</div>
					<template #footer>
						<Button label="Aceptar" icon="pi pi-times" class="p-button-text" @click="movAsociadoDlg = false"/>
					</template>
				</Dialog>

				<Dialog v-model:visible="dlgEnvioEmail" :modal="true">
					<template #header>
						Confirmación de envío de notificación
					</template>
					<div class="p-d-flex">
						<i class="pi pi-envelope p-mr-2" style="fontSize: 2rem"></i>
						<p>¿Está seguro que desea enviar los documentos seleccionado(s)?</p>
					</div>
					<template #footer>
						<div class="p-field p-col">
							<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="dlgEnvioEmail=false"/>
							<Button label="Enviar" icon="pi pi-send" class="p-button-text" @click="enviarACtahabientesSeleccionados"/>
						</div>
					</template>
				</Dialog>	

				<Dialog v-model:visible="dlgNoHayEmails" :style="{width: '450px'}" header="No hay E-mails" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span>No hay correos electronicos disponibles para esta cuenta!</span>
					</div>
					<template #footer>
						<Button label="Aceptar" icon="pi pi-times" class="p-button-text" @click="dlgNoHayEmails = false"/>
					</template>
				</Dialog>				

				<Dialog v-model:visible="enviandoEmailsDlg" :showHeader="true" :modal="true" :closable="false">			
					<div class="flex align-content-center flex-wrap card-container" :style="{width: '500px', height: '250'}">
						<div class="p-d-flex p-ai-center p-jc-center">
							<i class="pi pi-send p-m-2" style="font-size: 2rem"></i>
						</div>
						<div class="flex align-items-center justify-content-center mt-5">				
						<ProgressBar :value="secuencia">										
							<div class="font-light">
							Enviando {{secuencia}} de {{selectedMovs.length}}
							</div>
						</ProgressBar>
						</div>
					</div>
				</Dialog>
			</div>
		</div>
	</div>

</template>

<script>
import {FilterMatchMode} from 'primevue/api';
import SeleccionFecha from '../components/SeleccionFecha.vue';
import BuscarCtaHabiente from '../components/BuscarCtaHabiente.vue';
import MdsBuscarInmueble from '../components/MdsBuscarInmueble.vue';
import InvService from '../service/inv.service';
import CxcService from '../service/cxc.service';
import MdsService from '../service/mds.service';
import { CURRENT_APP_MODE } from '../service/constants';
import { read, utils, writeFileXLSX } from 'xlsx';

export default {
	setup() {
		const rowClass = (data) => {
            return data.anulado === 'S' ? 'row-anulada': null;
        };

		return { rowClass }
	},	
	components: { SeleccionFecha, BuscarCtaHabiente, MdsBuscarInmueble},
	data() {
		return {
			appMode: CURRENT_APP_MODE,
			mostrar_anulados: false,
			dlgBuscarCtaHabiente: false,
			dlgBuscarCtaHabienteRefresh: 0,
			CtahabienteNombre: '(Todos)',
			tituloCtaHabiente: (CURRENT_APP_MODE == 1? 'Inmueble': 'Cta.Habiente'),
			id_cta_habiente_info: '0',
			desdeUpdate: 0,
			InmuebleDisplay: 'Seleccionar...',
			inmuebleDialog: false,
			hasta: new Date(),
			desde: new Date(),
			trxs: null,
			anularDialog: false,
			movAsociadoDlg: false,
			puedeAnular: this.$store.getters['auth/tieneAcceso']('CC_ANULAR'),
			puedeImprimir: this.$store.getters['auth/tieneAcceso']('CC_EMITIR_COMPRABANTE_PAGO'),
			puedeEnviarEmail: (this.$store.getters['auth/tieneAcceso']('CC_EMITIR_COMPRABANTE_PAGO')),	
			puedeDescargar: this.$store.getters['auth/tieneAcceso']('CC_EMITIR_COMPRABANTE_PAGO'),
			dowloandItems: [	
    			{label: 'Exportar Listado Docs', icon: 'pi pi-file-excel', command: () => {this.exportarTrxsAXLS()}},
    			{label: 'Descargar Docs', icon: 'pi pi-file-pdf', command: () => {this.prepararBatchDocs()}}				
			],			
			dlgEnvioEmail: false,
			dlgNoHayEmails: false,
			enviandoEmailsDlg: false,
			selectedMovs: null,
			secuencia: 1,			
			emails: [],
			mov: {},
			lkpSucursales: this.$store.state.auth.currentAppCtx.Sucursales,			
			lkpTiposProducto: null,
			lkpMonedas: [],
			IdMoneda: '0',	
			IdMonedaSel: '0',
			loading: false,
			filteredData: [],
			filters: {
				'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
				fecha: {value: null, matchMode: FilterMatchMode.CONTAINS},
				movimiento_tipo: {value: null, matchMode: FilterMatchMode.CONTAINS},
				documento: {value: null, matchMode: FilterMatchMode.CONTAINS},
				cta_habiente: { value: null, matchMode: FilterMatchMode.CONTAINS },
				total: { value: null, matchMode: FilterMatchMode.EQUALS }				
			},			
			submitted: false
		}
	},
	mounted() {
		
		this.lkpMonedas = this.$store.state.auth.currentAppCtx.Monedas;
		
		if(this.lkpMonedas.length > 1) {
			this.lkpMonedas.splice(0, 0, {id_moneda: '0', moneda: '(Todas)'});
		} else {
			this.IdMoneda = this.lkpMonedas[0].id_moneda;
		}
		this.buscar();
	},
	computed: 
	{
		mostrarBotonBusquedaCtaHabiente(){
			return (this.id_cta_habiente_info == '0')
		},
		puedeEnviarEmails() {
			if(this.selectedMovs)
			{
				return (this.selectedMovs.length > 0) && this.puedeEnviarEmail;
			} else {
				return false;
			}
		},
		hayDatosParaDescargar() {
			if(this.trxs)
			{
				return (this.trxs.length > 0)
			} else {
				return false;
			}
		},
		totalDocumentos() {
			var totalAcumulado = 0.0;

			if (this.IdMonedaSel == '0') {
				return 0.00;
			}

			var docs = null;
			
			if(this.filteredData) {
				docs = this.filteredData;
			} else {
				docs = this.trxs;	
			}

			if(docs) {
				for(let doc of docs) {
					if(doc.anulado == 'N') {
						totalAcumulado += doc.total;
					};
				}
			}
		
			return totalAcumulado;
		},						
	},	
	methods: {
		formatDocument(Docto)
		{
			return Docto.toString().padStart(6, '0');
		},
		actualizarPeriodo(periodo)
		{
			this.desde = periodo.desde;
			this.hasta = periodo.hasta;
			this.periodoUpdate += 1;
		},
		buscarCtahabiente() {
			if(this.id_cta_habiente_info == '0'){								
				this.dlgBuscarCtaHabiente = true;
				this.dlgBuscarCtaHabienteRefresh++;
			} else {
				this.CtahabienteNombre = '(Todos)';
				this.id_cta_habiente_info = '0';		
			}
		},	
		cerrarDlgCtahabiente() {
			this.dlgBuscarCtaHabiente = false;
		},
		selCtaHabiente(CtaHabiente)
		{
			this.id_cta_habiente_info = CtaHabiente.id_cta_habiente_info;			
			this.CtahabienteNombre = CtaHabiente.nombre_comercial;	
			this.dlgBuscarCtaHabiente = false;
		},	
		seleccionarInmueble() {
			if(this.id_cta_habiente_info == '0'){								
				this.inmuebleDialog = true;
			} else {
				this.InmuebleDisplay = '(Todos)';
				this.id_cta_habiente_info = '0';		
			}			
		},
		cerrarDlgInmueble(){
			this.inmuebleDialog = false;
		},					
		elegirInmueble(Inmueble){
			this.id_cta_habiente_info = Inmueble.id_cta_habiente_info;			
			this.InmuebleDisplay = Inmueble.display;	
			this.CtahabienteNombre = Inmueble.nombre_documento;	
			this.inmuebleDialog = false;			
		},
		buscar(){
			this.loading = true;
			this.trxs = null;
			this.filteredData = null;
			CxcService.getHistorialTrxs(this.$store.state.auth.currentAppCtx, 
			{
				id: this.$store.state.auth.currentAppCtx.id_referencia,
				id_sucursal: this.lkpSucursales[0].id_sucursal,
				id_cta_habiente_info: this.id_cta_habiente_info,
				anulado: this.mostrar_anulados?"S":"N",
				id_tipo_doc: "",
				serie: "",
				no_docto: 0,
				id_moneda: this.IdMoneda,
				desde: this.desde,
				hasta: this.hasta
			}).then(data => {
				this.IdMonedaSel = this.IdMoneda;
				this.trxs = data;
				this.loading = false;
			}).catch(err => {
				this.trxs = [];
				this.loading = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: err, life: 3000})				
				}
			);	
		},
		async prepararBatchDocs(){
			this.loading = true;
			this.trxs = null;
			this.filtro = {
				id: this.$store.state.auth.currentAppCtx.id_referencia,
				id_sucursal: this.lkpSucursales[0].id_sucursal,
				id_cta_habiente_info: this.id_cta_habiente_info,
				anulado: this.mostrar_anulados?"S":"N",
				id_tipo_doc: "",
				serie: "",
				no_docto: 0,
				id_moneda: this.IdMoneda,
				desde: this.desde,
				hasta: this.hasta
			};			

			try {
				var response = await CxcService.prepararBatchDocs(this.$store.state.auth.currentAppCtx, this.filtro);

				//Create a Blob from the PDF Stream
				const file = new Blob(
				[response.data], 
				{type: 'application/zip'});
				//Build a URL from the file
				const fileURL = URL.createObjectURL(file);	

				var link = document.createElement('a');
				link.href = fileURL;
				link.download = 'HistorialDocs.zip';
				link.dispatchEvent(new MouseEvent('click'));

				this.loading = false;
			} catch (err)
			{
				this.trxs = [];
				this.loading = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: err, life: 3000})	
			};
		},		
		formatCurrency(moneda, value) {
			if((value) && (moneda!='0'))
				return value.toLocaleString('en-US', {style: 'currency', currency: moneda});
			return;
		},		
		hideDialog() {
			this.productDialog = false;
			this.submitted = false;
		},
		confirmarAnulacionMov(movimiento) {
			this.mov = movimiento;

			if(this.mov.id_mov_asociado)			
			{	
				this.movAsociadoDlg = true;		
			} else 
			{
				this.anularDialog = true;
			}
		},
		anularTrx() {
			CxcService.anularMovimiento(this.$store.state.auth.currentAppCtx, this.mov).then(() => {

					this.anularDialog = false;
					this.mov.anulado = 'S';
					if(!this.mostrar_anulados){
						this.trxs = this.trxs.filter(val => val.id_movimiento !== this.mov.id_movimiento);					
						this.mov = {};
					}
					this.$toast.add({severity:'success', summary: 'Éxito', detail: 'La transacción ha sido anulada', life: 3000})
			}
			).catch(
				data => {
					this.anularDialog = false;
					this.mov = {};
					this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
				}
			)
		},
		descargarPDF(movimiento) {	
			CxcService.getFormatoImpresionTrx(this.$store.state.auth.currentAppCtx, movimiento, false).then(
				response => {
					//Create a Blob from the PDF Stream
					const file = new Blob(
					[response.data], 
					{type: 'application/pdf'});
					//Build a URL from the file
					const fileURL = URL.createObjectURL(file);	

					var link = document.createElement('a');
					link.href = fileURL;
					link.download = `${movimiento.movimiento_tipo} ${movimiento.serie}-${movimiento.no_docto}.pdf`;
					link.dispatchEvent(new MouseEvent('click'));

					//Open the URL on new Window
					//window.open(fileURL);
				}				
			).catch(
				data => {
					this.anularDialog = false;
					this.mov = {};
					this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
				}
			)
		},
		filter(e){
			this.filteredData = e.filteredValue;
		},
		async enviarEmailTrx() {
			try {
				this.dlgEnvioEmail = false;
				this.loading = true;

				var notif = {						
					id: this.mov.id_movimiento,
					emails: this.emails
				};

				await CxcService.enviarEmailTrx(this.$store.state.auth.currentAppCtx, notif);

				this.loading = false;		
			} catch(error) {
				this.loading = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: error, life: 3000})
			}			
		},
		async confirmarEnvioXEmail(movimiento) {
			try{				
				this.mov = movimiento;

				if(this.appMode == 1) { // Multidomus
					this.emails =  await MdsService.getInmueblesEmailsNotificacion(this.$store.state.auth.currentAppCtx, movimiento.id_cta_habiente_info);
				} else {
					this.emails = await InvService.getCtaHabienteEmailsNotificacion(this.$store.state.auth.currentAppCtx, movimiento.id_cta_habiente_info);
				}
				
				if(this.emails.length > 0) {
					this.dlgEnvioEmail = true;
				} else {
					this.dlgNoHayEmails = true;	
				}
			} catch(error) {
				this.loading = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: error, life: 3000})
			}
		},		
		async enviarACtahabientesSeleccionados()
		{
			this.dlgEnvioEmail	= false;
			try
			{
				this.enviandoEmailsDlg = true;
				this.secuencia = 1;
				var notif;

				for(var i = 0; i < this.selectedMovs.length; i++)
				{												
					notif = {						
						id: this.selectedMovs[i].id_movimiento,
						emails: []
					};

					if(this.appMode == 1) { // Multidomus
						notif.emails =  await MdsService.getInmueblesEmailsNotificacion(this.$store.state.auth.currentAppCtx, this.selectedMovs[i].id_cta_habiente_info);
					} else {
						notif.emails = await InvService.getCtaHabienteEmailsNotificacion(this.$store.state.auth.currentAppCtx, this.selectedMovs[i].id_cta_habiente_info);
					}

					if(notif.emails.length > 0) {
						await CxcService.enviarEmailTrx(this.$store.state.auth.currentAppCtx, notif);
					}

					this.secuencia++;
				}
				this.secuencia = 1;
				this.enviandoEmailsDlg = false;
			}
			catch(error) {
				this.enviandoEmailsDlg = false;
				console.log(error);
			}
		},
		async exportarTrxsAXLS()
		{
			this.loading = true;
			try {
				var data;
				if(this.filteredData.length > 0) {
					data = this.filteredData;
				} else {
					data = this.$refs.dt.value;
				}
				var rows;

				if(this.appMode==0) {
					rows = data.map(doc => {
						return {
								"Fecha": doc.fecha,	
								"TipoDoc": doc.movimiento_tipo,
								"Documento": doc.documento,
								"Ctahabiente": doc.cta_habiente,																								
								"NIT": doc.nit,
								"Referencia": doc.referencia,
								"Observaciones": doc.observaciones,
								"Moneda": doc.moneda,
								"Total": doc.total,
								"Documento Asociado": doc.documento_asoc,
								"Fecha Creacion": doc.fecha_creacion,
								"Anulado": doc.anulado,
								"Usuario": doc.usuario,		
								"Id.Interno": doc.id_movimiento
								}
					}).flat();
				} else {
					rows = data.map(doc => {
						return {
							"Fecha": doc.fecha,	
							"TipoDoc": doc.movimiento_tipo,
							"Documento": doc.documento,
							"Inmueble": doc.cta_habiente,																								
							"NIT": doc.nit,
							"Referencia": doc.referencia,
							"Observaciones": doc.observaciones,
							"Moneda": doc.moneda,
							"Total": doc.total,
							"Documento Asociado": doc.documento_asoc,
							"Fecha Creacion": doc.fecha_creacion,
							"Anulado": doc.anulado,
							"Usuario": doc.usuario,		
							"Id.Interno": doc.id_movimiento
						}
					}).flat();
				}
  				const ws = utils.json_to_sheet(rows);
				var wb = utils.book_new();
				utils.book_append_sheet(wb, ws, `${this.id_moneda} - Transacciones`);
				writeFileXLSX(wb, "Transacciones.xlsx");

				this.loading = false;
			} catch (error)
			{
				this.loading = false;				
				this.$toast.add({severity:'error', summary: 'Error', detail: error, life: 3000})								
			}	
		},
		exportCSV() {
			this.$refs.dt.exportCSV();
		}
	}
}
</script>

<style scoped lang="scss">
.anulado {
  color: #bd2130;
  font-size: small;
  font-weight: bold;
  text-align: center;
  margin: auto;  
}

.table-header {
	display: flex;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}
/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
	display: none;
}

@media screen and (max-width: 960px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				border-bottom: 1px solid var(--surface-d);

				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					&:last-child {
						text-align: center;
					}

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
						display: inline-block;
						width: 60%;
					}

					.p-rating {
						display: inline-block;
					}
				}
			}

			.p-datatable-tbody > tr.p-rowgroup-footer{
				display: flex;
			}
		}
	}
}

::v-deep(.p-toolbar) {
	flex-wrap: wrap;
	.p-button {
		margin-bottom: .25rem;
	}
}

::v-deep(.row-anulada) {
    background-color: rgba(0,0,0,.15) !important;
}
</style>    

